<template>
  <main id="content" role="main">
    <section class="report_group">
      <section class="report_box">
        <div class="letter-msg">
          {{type}}
          <div class="letter-comm-msg" v-if="type == 1">
            <div class="logo-area">
              <div class="type-img"></div>
              <div class="type-text">
                <span>DREAMPLUS</span>
                <strong>NEWSLETTER</strong>
              </div>
            </div>
            <div class="msg-area">
              <strong>DREAMPLUS NEWSLETTER</strong>를 구독해 주셔서 감사합니다.<br>
              유익한 컨텐츠로 여러분께 도움이 되어 드리겠습니다.
            </div>
          </div>
          <div class="letter-comm-msg" v-if="type == 2">
            <div class="logo-area">
              <div class="type-img"></div>
              <div class="type-text">
                <span>DREAMPLUS</span>
                <strong>NEWSLETTER</strong>
              </div>
            </div>
            <div class="msg-area">
              {{date.getFullYear()}}년 {{date.getMonth() + 1}}월 {{date.getDate()}}일 기준으로<br>
              드림플러스의 소식을 원하지 않으신다면 <br class="hidden">하단의 버튼을 클릭해 주세요.
            </div>
            <div class="submit-area">
              <div class="reject-box">
                <div class="mail">
                  <span v-if="hasEmail">{{email}}</span>
                  <span v-else><input type="text" v-model="email" style="width:470px;" placeholder="이메일 주소를 알려주세요."/></span>
                </div>
                <div class="guide">
                  <strong>DREAMPLUS NEWSLETTER</strong> 수신을<br class="hidden"> 거부하시겠습니까?
                </div>
                <div class="btns">
                  <div class="col">
                    <a href="" class="btn btn-line disabled" @click.prevent="cancelSubscription">수신거부</a>
                  </div>
                  <div class="col">
                    <a href="" class="btn btn-cancel" @click.prevent="cancel">취소</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="letter-comm-msg" v-if="type == 3">
            <div class="logo-area">
              <div class="type-img"></div>
              <div class="type-text">
                <span>DREAMPLUS</span>
                <strong>NEWSLETTER</strong>
              </div>
            </div>
            <div class="msg-area">
              {{date.getFullYear()}}년 {{date.getMonth() + 1}}월 {{date.getDate()}}일 기준으로<br>
              <strong>DREAMPLUS NEWSLETTER</strong> 수신을 거부했습니다.
            </div>
            <!-- <div class="submit-area">
              <router-link :to="{name:'LetterRead', params:{email: email}}"  class="link-read">다시 구독하기</router-link>
            </div> -->
            <div class="submit-area">
              <a href="" class="link-read" @click.prevent="resubscribe">다시 구독하기</a>
            </div>
          </div>
          <div class="letter-comm-msg" v-if="type == 4">
            <div class="logo-area">
              <div class="type-img"></div>
              <div class="type-text">
                <span>DREAMPLUS</span>
                <strong>NEWSLETTER</strong>
              </div>
            </div>
            <div class="msg-area">
              <strong>DREAMPLUS NEWSLETTER</strong>를 다시 구독해 주셔서 감사합니다.<br>
              유익한 컨텐츠로 여러분께 도움이 되어 드리겠습니다.
            </div>
          </div>
          <div class="text-center">
            <router-link :to="{name:'LetterList', params:{}}"  class="link-old">지난호 보기</router-link>
          </div>
        </div>
      </section>
    </section>
  </main>
</template>
<script>

import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { APIs } from '@/common/portalApi';

export default {
  name: 'LetterMsg',
  mixins: [
    mixinHelperUtils
  ],
  
  components: {
  },
  
  data() {
    return {
      userInfo: null,
      date: null,
      hasEmail: false,
      email: null,
      type: 1,
    };
  },
  created() {
    if (this.$route.query.type && this.$route.query.email) {
      console.log(this.$route.query.type, this.$route.query.email);
      this.$router.replace({name:'LetterMsg', params:{
        type: this.$route.query.type, 
        email: this.$route.query.email
      }});
      return;
    }
  },
  mounted() {
    if (!this.$route.params.type) {
      // to main
      this.$router.replace({ name: 'Home' });
    }
    this.type = this.$route.params.type;
    this.email = this.$route.params.email;
    if (this.email) {
      this.hasEmail = true;
    }
    this.date = new Date();
  },
  
  methods: {
    cancelSubscription() {
      this.portalApiClient({
        url: `${APIs.NEWS_LETTER_SUBSCRIPTION}`,
        method: 'delete',
        data: {
          email: this.email,
          isSubscription: false
        }
      }).then(({ data }) => {
        if (data.result) {
          this.type = 3;
        } else {
          this.showAlert(data.message);
        }
      });
    },
    
    cancel() {
      this.$router.back();
    },

    resubscribe() {
      this.portalApiClient({
        url: `${APIs.NEWS_LETTER_SUBSCRIPTION}`,
        method: 'put',
        data: {
          email: this.email,
          isSubscription: true
        }
      }).then(({ data }) => {
        if (data.result) {
          this.type = 4;
        } else {
          this.showAlert(data.message);
        }
      });
    }
  },
};
</script>

<style>
</style>
